/* VISUAL BUILDER
========================================================================== */
.visual-builder {
	&-row,
	&-column{
		font-size: 0;
		letter-spacing: 0;
	}
	
	&-row {
		position: relative;
		display: table;
		width: 100%;

		> .visual-builder-content-wrapper {
			position: absolute;
			display: table-cell;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			> .visual-builder-content {
				display: table;
				width: 100%;
				height: 100%;
				table-layout: fixed;
			}
		}
	}
	
	&-column {
		position: relative;
		display: table-cell;
		vertical-align: middle;

		> .visual-builder-content-wrapper {
			position: absolute;
			display: block;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			> .visual-builder-content {
				position: relative;
				width: 100%;
				height: 100%;
			}
		}

		.visual-builder-content-rows {
			@extend %flex;
			flex-direction: column;
		}

		.visual-builder-row {
			display: block;
			overflow: hidden;

			.visual-builder-content-wrapper {
				position: relative;
				display: block;
			}
		}
	}
	
	&-content {
		overflow: hidden;
	}
	
	&-image {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		@include transform(translate(-50%, -50%));

		&.is-stretched-vertically {
			width: auto;
			height: 100%;
		}

		&.is-not-centered {
			top: 0;
			left: 0;
			@include transform(none);
		}
	}
	
	&-color {
		display: block;
		width: 100%;
		height: 100%;
	}
}