.block-product-promo-countdown {
  margin-top: -1px;

  .countdown-timer-wrapper {
    padding: 3px 4px 3px;
    background: #EB0122;

    .countdown-timer-controls {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .countdown-counter {
        display: inline-block;
        vertical-align: middle;
        min-width: 15px;
        margin-right: 2px;
        font-size: 11px;
        color: #FFFFFF;
        letter-spacing: 0;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        text-align: center;
      }

      .countdown-label {
        display: inline-block;
        vertical-align: middle;
        opacity: 0.7;
        font-size: 9px;
        color: #FFFFFF;
        letter-spacing: 0;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
      }

      .countdown-timer-number.days {

        .countdown-counter {
          min-width: 8px;
        }
      }
    }
  }
}
