.block-newsletter-subscription {

	.content {

		.title {
			margin-bottom: 10px;
			font-size: 18px;
			font-weight: $bold;
			line-height: 18px;
			text-transform: uppercase;
		}

		.text {
			margin-bottom: 20px;
			font-size: 12px;
			font-weight: $regular;
			line-height: 16px;
		}
	}

	.newsletter-field {
		display: inline-block;
		vertical-align: middle;
		width: calc(70% - 5px);
		height: 48px;
		margin-right: 5px;

		@include media("<=tablet") {
			width: calc(60% - 5px);
		}

		input[name="email"] {
			display: block;
			width: 100%;
			height: 48px;
			padding: 15px;
			border: 1px solid $border-color-dark;
			font-size: 12px;
			font-weight: $regular;
			line-height: 16px;

			&:hover,
			&:focus {
				@include box-shadow(none);
			}

			&::-webkit-input-placeholder {
				font-style: normal;
			}

			&:-moz-placeholder {
				font-style: normal;
			}

			&::-moz-placeholder {
				font-style: normal;
			}

			&:-ms-input-placeholder {
				font-style: normal;
			}
		}
	}

	.button-wrapper {
		display: inline-block;
		vertical-align: middle;
		width: 30%;
		height: 48px;
		max-height: 48px;

		@include media("<=tablet") {
			width: 40%;
		}

		button {
			width: 100%;
			height: 48px;
			background: #000;
			text-align: center;

			span {
				color: #fff;
				font-size: 14px;
				font-weight: $semi-bold;
				line-height: 16px;
			}

			&:hover,
			&:focus {
				background-color: $button-hover-background-color;
			}
		}
	}

	.consents {
		margin-top: 15px;

		.form-label-wrapper {
			display: inline-block !important;
			width: auto;

			&:before {
				left: auto !important;
				right: -8px !important;
			}
		}

		.form-label {
			padding-left: 5px !important;
			font-size: 12px;
			font-weight: $regular;
			line-height: 16px;
		}
	}

	.form-element-error {
		position: absolute;
		margin-top: 2px;
		color: #000;
	}

	.form-element-success {
		position: absolute;
		margin-top: 2px;
	}
}
