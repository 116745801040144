.search-bar {
	position: relative;
	z-index: 40;
	width: 100%;
	height: 56px;
	margin-top: -56px;
	background: #fff;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,0.1);
	visibility: hidden;
	opacity: 0;

	.block-product-search {
		width: 100%;
		max-width: 1200px;
		margin: 0 auto;
		padding: 0 20px;

		@include media("<=tablet") {
			display: none;
			margin-right: 0;
			background-color: #f7f7f7;
		}

		.search-form {
			position: relative;

			&:before {
				content: "";
				@include transform(translateY(-50%));
				position: absolute;
				display: block;
				top: 50%;
				left: 0;
				width: 20px;
				height: 20px;
				background: url('/images/icons/icon-search-pale.svg') no-repeat;
			}

			.search-form-input {
				width: 100%;
				height: 56px;
				padding: 0 30px 0 36px;
				font-size: 15px;
				font-weight: $regular;
				border: 0;

				@include media("<=tablet") {
					@include transform(translateX(0)!important);

					display: inline-block;
					vertical-align: top;
					width: calc(100% - 40px);
					height: 60px;
					padding: 0 10px 0 0;
					font-family: $secondary-font-family;
					font-size: 14px;
					font-style: normal;
					visibility: visible!important;
					opacity: 1!important;
					border: none;
					background-color: transparent;
					text-transform: uppercase;
					letter-spacing: $letter-spacing-medium;
				}

				&:hover,
				&:focus {
					@include box-shadow(none);
				}

				&::-webkit-input-placeholder {
					@include media("<=tablet") {
						font-style: normal;
					}
				}
				&:-moz-placeholder {
					@include media("<=tablet") {
						font-style: normal;
					}
				}
				&::-moz-placeholder {
					@include media("<=tablet") {
						font-style: normal;
					}
				}
				&:-ms-input-placeholder {
					@include media("<=tablet") {
						font-style: normal;
					}
				}
			}

			.search-form-button {
				@include transform(translateY(-50%));
				position: absolute;
				min-width: 140px;
				height: 40px;
				top: 50%;
				right: 0;
				padding: 0;
				font-size: 0;
				background-color: #000;

				@include media("<=tablet") {
					@include transform(translateY(0));
					position: static;
					width: 40px;
					height: 60px;
					display: inline-block;
					vertical-align: top;
				}

				span {
					font-size: 14px;
					color: #fff;
					font-weight: 600;
					text-transform: none;
				}
			}

			&.is-always-on {
				input {
					@include transform(none !important);
					visibility: visible !important;
					opacity: 1 !important;
				}
			}
		}
	}
}
