.sliding-panel {
	position: fixed;
	top: 153px;
	right: -380px;
	z-index: z(sliding-panel);
	width: 380px;
	height: 100%;
	padding: 35px 40px;
	background-color: #fff;

	@include media("<=tablet") {
		top: 93px!important;
		right: -100%;
		width: 100%;
		height: calc(100vh - 60px);
		padding: 0;
		overflow: hidden;
	}

	.container {
		position: absolute;
		width: calc(100% - 80px);
		visibility: hidden;
		opacity: 0;

		@include media("<=tablet") {
			position: static;
			width: 100%;
			visibility: visible !important;
			opacity: 1 !important;
		}

		&[data-type="profile"] .content {
			@include media("<=tablet") {
				padding: 20px 10px;
			}

			.register-link {
				display: block;
				width: 100%;
			}
		}

		&[data-type="cart"] .content {
			@include media("<=tablet") {
				padding: 0 10px 20px 10px;
			}
		}

		&[data-type="checkout"] .content {
			@include media("<=tablet") {
				padding: 20px 10px;
			}
		}
	}

	.title {
		padding-bottom: 25px;
		font-family: $secondary-font-family;
		font-size: 24px;
		border-bottom: 1px solid $border-color-light;
		letter-spacing: $h1-letter-spacing;

		@include media("<=tablet") {
			line-height: 24px;
			padding: 22px 10px 19px;
		}
	}

	.or-text {
		font-weight: $light;
		font-size: 12px;
		letter-spacing: $letter-spacing-narrow;
		text-align: center;
		margin: 20px 0;
	}

	.forgotten-password-link {
		display: inline-block;
		margin-top: 10px;
		font-size: 12px;
		font-weight: $light;
		color: $body-text-color;
		text-decoration: underline;
		letter-spacing: $letter-spacing-narrow;
	}

	/* Login */
	.login-box {
		margin-top: 30px;

		@include media("<=tablet") {
			margin-top: 0;
		}

		.submit-button {
			display: block;
			width: 100%;
		}
	}

	/* Profile */
	.profile-links {

		@include media("<=tablet") {
			padding: 0 10px;
		}

		li {
			&:not(:last-child) {
				border-bottom: 1px solid $border-color-light;
			}
		}

		a {
			position: relative;
			display: block;
			line-height: 30px;
			padding: 20px 0;

			@include media("<=tablet") {
				line-height: 14px;
				padding: 19px 0 21px 0;
			}
		}

		i {
			position: absolute;
			top: 29px;
			left: 0;
			color: $body-text-color;

			@include media("<=tablet") {
				top: 20px;
			}
		}

		.text {
			padding-left: 30px;
			font-family: $secondary-font-family;
			font-size: 14px;
			letter-spacing: $letter-spacing-medium;
		}
	}

	/* Cart */
	.products {
		height: calc(100vh - 384px);
		overflow-y: auto;

		@include media("<=tablet") {
			height: calc(100vh - 253px);
		}
	}

	.bottom-section {
		padding-top: 20px;
		border-top: 1px solid $border-color-light;
	}

	.view-link {
		float: left;
		font-size: 0;
		color: $body-text-color;

		i {
			display: inline-block;
			vertical-align: middle;
			margin-right: 10px;
			font-size: 14px;
		}

		.text {
			display: inline-block;
			vertical-align: middle;
			font-family: $secondary-font-family;
			font-size: 14px;
			text-decoration: underline;
			letter-spacing: $letter-spacing-medium;
		}
	}

	.link-price {
		overflow: hidden;
	}

	.free-delivery-message,
	.free-delivery-left-sum {
		margin-top: 15px;
	}

	.total {
		float: right;
		font-family: $secondary-font-family;
		font-size: 0;

		.text {
			font-size: 14px;
			letter-spacing: $letter-spacing-medium;
		}

		.value {
			font-size: 17px;
			font-weight: $medium;
		}
	}

	.purchase {
		display: block;
		width: 100%;

		margin-top: 20px;
	}

	.empty-cart-text {
		margin-top: 30px;

		@include media("<=tablet") {
			padding-bottom: 20px;
		}
	}

	/* Checkout */
	.purchase-without-registration {
		display: block;
		width: 100%;
	}
}
