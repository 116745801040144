// General
.block-navigation {
  z-index: z(navigation);
  padding: 0 20px;
  font-size: 0;

  @include media("<=tablet") {
    position: fixed;
    top: 92px;
    left: 0;
    width: 100%;
    height: 0;
    max-height: calc(100vh - 60px);
    padding: 0;
    text-align: left;
    overflow: hidden;
    background-color: #fff;

    .block.block-product-search {
      background: #EEEEEE;
      padding: 20px;

      .search-form {
        position: relative;

        .search-form-button {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 15px;
          width: 20px;
          height: 20px;
          background: url("/images/icons/icon-search.svg") no-repeat;

          span {
            display: none;
          }
        }

        .search-form-input {
          width: 100%;
          height: 48px;
          padding: 0 10px 0 48px;

          &::-webkit-input-placeholder {
            font-size: 12px;
            color: #A4A4A4;
            line-height: 16px;
            font-weight: 400;
            font-family: "Montserrat", sans-serif;
          }
          &:-moz-placeholder {
            font-size: 12px;
            color: #A4A4A4;
            line-height: 16px;
            font-weight: 400;
            font-family: "Montserrat", sans-serif;
          }
          &::-moz-placeholder {
            font-size: 12px;
            color: #A4A4A4;
            line-height: 16px;
            font-weight: 400;
            font-family: "Montserrat", sans-serif;
          }
          &:-ms-input-placeholder {
            font-size: 12px;
            color: #A4A4A4;
            line-height: 16px;
            font-weight: 400;
            font-family: "Montserrat", sans-serif;
          }
        }
      }
    }
  }

  .navigation-menu {
    height: 80px;
    padding-top: 33px;

    @include media("<=tablet") {
      height:  auto;
      padding-top: 0;
    }
  }

  .navigation-menu-item {
    display: inline-block;
    vertical-align: top;

    @include media("<=tablet") {
      display: block;
      border-top: 1px solid $border-color-dark;

      &.is-opened {

        & > a {

            .chevron {
              margin-top: -2px;
              transform: rotate(180deg);
              -webkit-transform: rotate(180deg);
              -moz-transform: rotate(180deg);
            }
          }
      }
    }

    &:not(:last-child) {
      margin-right: 40px;

      @include media("<=tablet") {
        margin-right: 0;
      }
    }

    & > a {
      position: relative;
      display: block;
      font-family: $secondary-font-family;
      font-weight: 600;
      font-size: 13px;
      letter-spacing: $letter-spacing-wide;

      @include media("<=tablet") {
        line-height: 13px;
        padding: 20px;
        font-size: 13px;
        color: $body-text-color;

        .chevron {
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          width: 10px;
          height: 6px;
          background: url("/images/icons/icon-chevron-down-large.svg");
          transition: transform 0.3s;
          -webkit-transition: -webkit-transform 0.3s;
          -moz-transition: -moz-transform 0.3s;
          -o-transition: -o-transform 0.3s;
        }
      }
    }

    &:hover a,
    &.is-active a {

      &:before {
        content: "";
        position: absolute;
        bottom: -33px;
        width: calc(100%);
        height: 1px;
        background-color: #000;

        @include media("<=tablet") {
          content: none;
        }
      }
    }

    &[data-constant="SALE"] {
      a {
        color: $promo-color;
      }

      &:hover a:before,
      &.is-active a:before {
        background-color: $promo-color;
      }
    }
  }

  .search-bar-mobile {
    display: none;

    @include media("<=tablet") {
      display: block;
    }
  }

  .mobile-links {

    @include media("<=tablet") {
      border-top: 1px solid $border-color-dark;
    }

    li a {

      @include media("<=tablet") {
        position: relative;
        display: block;
        line-height: 12px;
        padding: 24px 10px 20px 10px;
        font-size: 12px;
        letter-spacing: $letter-spacing-narrow;
        color: $body-text-color;
      }

      &:after {
        @include media("<=tablet") {
          content: "\f105";
          position: absolute;
          top: 25px;
          right: 10px;
          font-family: FontAwesome;
          font-size: 14px;
        }
      }
    }

    i {
      @include media("<=tablet") {
        font-size: 14px;
        margin-right: 2px;
      }
    }
  }
}

// Mega Menu
.navigation-mega-menu {
  position: absolute;
  top: 121px;
  left: 0;
  z-index: z(navigation);
  width: 100%;
  height: 290px;
  padding: 40px 50px 0 50px;
  text-align: left;
  opacity: 0;
  visibility: hidden;
  background-color: #fff;

  @include media("<=tablet") {
    display: none;
  }

  .navigation-mega-menu-inner {
    display: block;
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;

    ul {
      width: 100%;
    }
  }
}

.navigation-mega-menu-item {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  width: 100%;

  @include media("<=tablet") {
    position: static;
    height: 0;
    visibility: visible !important;
    opacity: 1 !important;
    background-color: #f7f7f7;
    overflow: hidden;
  }

  .navigation-mega-menu-column {
    display: inline-block;
    width: 145px;
    margin-right: 95px;
    vertical-align: top;

    &:last-child {
      margin-right: 0;
    }

    @include media("<=1160px") {
      margin-right: 40px;
    }

    @include media("<=990px") {
      width: 120px;
      margin-right: 20px;
    }

    @include media("<=tablet") {
      display: block;
      width: 100%;
      margin-right: 0;
    }
  }

  .navigation-mega-menu-banner {
    display: inline-block;
    width: 100%;
    max-width: 335px;
    float: right;
    vertical-align: top;

    @include media("<=tablet") {
      display: none;
    }

    img {
      width: 100%;
    }
  }
}

.is-opened .navigation-mega-menu-item {


  @include media("<=tablet") {
    border-top: 1px solid $border-color-dark;
  }
}

.navigation-mega-menu-item-section {
  width: 100%;
  margin-bottom: 15px;

  @include media("<=tablet") {
    display: block;
    width: 100%;
    margin-bottom: 0;
    padding: 0;
    border-bottom: 1px solid $border-color-dark;
  }

  &.is-opened {
    & .second-level-category {
      @include media("<=tablet") {
        border-color: $border-color-dark;
      }
    }
  }
}

.second-level-category {
  display: block;

  @include media("<=tablet") {
    position: relative;
    padding: 20px;
    margin-bottom: 0;
    border-bottom: 1px solid transparent;
  }

  &:last-child {
    @include media("<=tablet") {
      margin-bottom: 0;
    }
  }

  .image {
    display: inline-block;
    vertical-align: middle;
    width: 40px;

    @include media("<=tablet") {
      width: 32px;
    }

    img {
      max-width: 23px;
      max-height: 100%;
    }
  }

  .name {
    display: inline-block;
    vertical-align: middle;
    font-family: $secondary-font-family;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0;

    @include media("<=tablet") {
      width: calc(100% - 32px);
      color: $body-text-color;
    }
  }
}
