.cart-countdown-timer {
  display: block;
  background: #EA0121;

  .inner {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 6px 20px 4px;
    text-align: center;

    @include media("<=tablet") {
      padding: 6px 5px 4px;
    }

    .name {
      margin-bottom: 1px;
      color: #FFFFFF;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;

      @include media("<=tablet") {
        margin-bottom: 2px;
        font-size: 11px;
      }
    }

    .countdown-timer {
      color: #FFFFFF;
      font-family: 'Montserrat', sans-serif;
      font-size: 13px;
      font-weight: 600;

      .count {
        display: inline-block;
        vertical-align: middle;
        width: 18px;
      }

      .days {

        .count {
          text-align: right;
        }
      }

      .text {
        display: inline-block;
        vertical-align: middle;
        color: rgba(255, 255, 255, 0.8);
        font-family: 'Montserrat', sans-serif;
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 0;
      }
    }
  }

  @include media("<=tablet") {
    position: relative;
    top: 93px;
  }
}
