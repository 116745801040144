.back-to-top {
	@include border-radius(50%);
	
	position: fixed;
	bottom: 40px;
	right: 40px;
	z-index: 9;
	display: none;
	width: 40px;
	height: 40px;
	background: $back-to-top-background-color;
	cursor: pointer;

	@include media("<=tablet"){
		bottom: 20px;
		right: 20px;
	}
	
	i {
		@include transform(translate(-50%, -50%));
		
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		color: #fff;
	}
}