.flash-messages-wrapper {
	@include transform(translate(0, -100%));

	position: fixed;
	top: 0;
	left: 0;
	z-index: 99;
	width: 100%;
	color: #fff;
	font-size: 14px;
	font-weight: $semi-bold;
	text-align: center;
	-webkit-animation: flash-message 7s;
	-o-animation: flash-message 7s;
	animation: flash-message 7s;
}
.flash-message {
	padding: 10px;
}
.flash-message.success {
	background-color: $flash-message-success-background-color;
}
.flash-message.error {
	background-color: $flash-message-error-background-color;
}
