.block-login-box {

	.form-group {
		margin-bottom: 10px;
	}

	input {
		width: 100%;
	}

	.form-element-error {
		&.is-shown {
			margin-bottom: 5px;
		}
	}
	
}