/* STATES
========================================================================== */
.is {
	&-hidden {
		display: none !important;
	}

	&-mobile {
		display: block;

		@include media(">tablet"){
			display: none;
		}
	}
	
	&-loading {
		&:before {
			@include animation(fadeIn $default-animation-duration);

			visibility: visible;
			opacity: 1;
		}
		
		&:after {
			@include animation(rotating 0.75s infinite linear, fadeIn $default-animation-duration);
			
			visibility: visible;
			opacity: 1;
		}
	}
	
	&-loaded {
		&:before {
			@include animation(fadeOut $default-animation-duration);
		}
		
		&:after {
			@include animation(rotating 0.75s infinite linear, fadeOut $default-animation-duration);
		}
	}
	
	&-sliding-panel-opened {
		padding-right: 15px;
		overflow-y: hidden;
		
		body {
			overflow-y: hidden;
		}

		@include media("<=tablet") {
			padding-right: 0;
		}
	}
	
	&-touchless {
		pointer-events: none;
	}
	
	&-navigation-opened {
		overflow-y: hidden;
		
		body {
			overflow-y: hidden
		}
	}
}
.has {
	&-opened-pop-up {
		overflow: hidden;
	}
	
	&-loader {
		position: relative;
		
		&:before {
			content: "";
			position: absolute;
			display: block;
			top: 0;
			left: 0;
			z-index: z(loader-overlay);
			width: 100%;
			height: 100%;
			background: rgba(255, 255, 255, 0.55);
			visibility: hidden;
			opacity: 0;
			-webkit-animation-fill-mode: forwards;
			-moz-animation-fill-mode: forwards;
			-o-animation-fill-mode: forwards;
			animation-fill-mode: forwards;
		}
		
		&:after {
			content: "";
			position: fixed;
			display: block;
			top: 50%;
			left: 50%;
			z-index: z(loader);
			width: 50px;
			height: 50px;
			margin-top: -25px;
			margin-left: -25px;
			border: 5px solid rgba(0, 0, 0, 0.24);
			border-right-color: rgba(0, 0, 0, 0.94);
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			border-radius: 50%;
			visibility: hidden;
			opacity: 0;
			-webkit-animation-fill-mode: forwards;
			-moz-animation-fill-mode: forwards;
			-o-animation-fill-mode: forwards;
			animation-fill-mode: forwards;
		}
	}
}
.clear-both {
	clear: both;
}

@-webkit-keyframes fadeIn {
	0% {
		visibility: hidden;
		opacity: 0;
	}
	1% {
		visibility: visible;
		opacity: 0;
	}
	100% {
		visibility: visible;
		opacity: 1;
	}
}
@-moz-keyframes fadeIn {
	0% {
		visibility: hidden;
		opacity: 0;
	}
	1% {
		visibility: visible;
		opacity: 0;
	}
	100% {
		visibility: visible;
		opacity: 1;
	}
}
@keyframes fadeIn {
	0% {
		visibility: hidden;
		opacity: 0;
	}
	1% {
		visibility: visible;
		opacity: 0;
	}
	100% {
		visibility: visible;
		opacity: 1;
	}
}
@-webkit-keyframes fadeOut {
	0% {
		visibility: visible;
		opacity: 1;
	}
	99% {
		visibility: visible;
		opacity: 0;
	}
	100% {
		visibility: hidden;
		opacity: 0;
	}
}
@-moz-keyframes fadeOut {
	0% {
		visibility: visible;
		opacity: 1;
	}
	99% {
		visibility: visible;
		opacity: 0;
	}
	100% {
		visibility: hidden;
		opacity: 0;
	}
}
@keyframes fadeOut {
	0% {
		visibility: visible;
		opacity: 1;
	}
	99% {
		visibility: visible;
		opacity: 0;
	}
	100% {
		visibility: hidden;
		opacity: 0;
	}
}
@-webkit-keyframes rotating {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@-moz-keyframes rotating {
	0% {
		-moz-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-moz-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@-ms-keyframes rotating {
	0% {
		-ms-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-ms-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@-o-keyframes rotating {
	0% {
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes rotating {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}