// Configuration
@import "config";
@import "base/fonts";
@import "base/animations";
@import "framework/reset";

// Core components
@import "components/layout";
@import "components/buttons";
@import "components/forms";
@import "components/headings";
@import "components/links";
@import "components/pagination";
@import "components/visual-builder";

// Custom components
@import "components/header";
@import "components/cart-countdown-timer";
@import "components/navigation";
@import "components/overlay";
@import "components/sliding-panel";
@import "components/sliding-panel-product";
@import "components/login-box";
@import "components/product-prices";
@import "components/product-promo-countdown";
@import "components/flash-message";
@import "components/product-search";
@import "components/information-bar";
@import "components/breadcrumbs";
@import "components/footer";
@import "components/news";
@import "components/newsletter-subscription";
@import "components/social-links";
@import "components/pop-up";
@import "components/back-to-top";
@import "components/mobile-icons";
@import "base/states";

.main {
	border-bottom: 1px solid $border-color-dark;

	&.is-sticky-header {
		margin-top: 120px;
	}

	@include media("<=tablet") {
		margin-top: 94px;
	}
}

body {
	&[data-matched-route-name="orderCheckoutNotRegistered"],
	&[data-matched-route-name="orderCheckoutRegistered"] {
		background-color: $lighter-gray;

		.main {
			top: 0;
			border-top: none;

			@include media("<=tablet") {
				margin-top: 0;
			}
		}

		header {
			@include media("<=tablet") {
				position: relative;
			}
		}
	}

	&[data-matched-route-name="orderCart"] {

		footer {
			display: none;
		}
	}
}
