/* BUTTONS
========================================================================== */
%button {

	display: inline-block;
	line-height: normal;
	padding: $button-padding;
	font-family: $secondary-font-family;
	background-color: $button-background-color;
	color: $button-text-color;
	text-decoration: none;
	cursor: pointer;
	font-size: 14px;
	font-weight: $semi-bold;
	text-align: center;
	letter-spacing: $letter-spacing-wide;

	@include media("<=tablet") {
		padding: $button-mobile-padding;
	}

	@if (variable_exists(button-transition)) {
		@include transition($button-transition);
	}

	&:hover,
	&:focus {
		background-color: $button-hover-background-color;
		color: $button-hover-text-color;
		text-decoration: none;
	}

	&[disabled] {
		background-color: $button-disabled-background-color;
		color: $button-disabled-text-color;
		border: $button-disabled-border;
		cursor: not-allowed;
	}

	&::-moz-focus-inner {
		padding: 0;
		border: 0;
	}
}

%button-white {
	display: inline-block;
	line-height: normal;
	padding: $bordered-button-padding;
	font-family: $secondary-font-family;
	background-color: #fff;
	border: 1px solid #000;
	color: $body-text-color;
	text-decoration: none;
	cursor: pointer;
	font-size: 14px;
	font-weight: $semi-bold;
	text-align: center;
	letter-spacing: $letter-spacing-wide;

	@include media("<=tablet") {
		padding: $button-mobile-padding;
	}

	@if (variable_exists(button-transition)) {
		@include transition($button-transition);
	}

	&:hover,
	&:focus {
		background-color: #fff;
		color: $body-text-color;
		text-decoration: none;
	}

	&[disabled] {
		background-color: #fff;
		color: $body-text-color;
		border: $button-disabled-border;
		cursor: not-allowed;
	}

	&::-moz-focus-inner {
		padding: 0;
		border: 0;
	}
}

button,
.button {
	@extend %button;
}

.button-white {
	@extend %button-white;
}

button.fancybox-button[disabled],
button.fancybox-close-small[disabled] {
	border: 0 none;
	background-color: transparent;
}

button.fancybox-button:hover,
button.fancybox-button:focus,
button.fancybox-close-small:hover,
button.fancybox-close-small:focus {
	background-color: transparent;
}
