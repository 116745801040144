footer {
	@extend %fluid-constrained;
	@extend %align-center;
	padding: 0 20px;

	.top {
		padding: 60px 0;
		font-size: 0;

		@include media("<=tablet") {
			padding: 40px 0;
		}
	}

	.section {
		display: inline-block;
		vertical-align: top;
	}

	.logo-newsletter-socials {
		width: calc(60% - 20px);
		margin-right: 20px;

		@include media("<=tablet") {
			display: block;
			width: 100%;
			margin-right: 0;
			margin-bottom: 40px;
		}

		.content {
			max-width: 450px;

			.logo {
				height: 32px;
				margin-bottom: 20px;

				img {
					max-width: 100%;
				}
			}

			.newsletter {
				margin-bottom: 20px;

				.block-newsletter-subscription {

					.image {
						display: none;
					}

					.title {
						display: block;
						color: #000;
						font-size: 12px;
						line-height: 16px;
						font-weight: 400;
						margin-bottom: 15px;
					}

					.form-element-error {
						color: #EA0122;
					}
				}
			}
		}
	}

	.information {
		width: calc(20% - 10px);
		margin-right: 20px;

		&:last-child {
			margin-right: 0;
		}

		@include media("<=tablet") {
			width: calc(50% - 10px);
		}

		.content {
			font-size: 12px;
			font-weight: $regular;
			line-height: 16px;
		}

		li {
			&:not(:last-child) {
				margin-bottom: 10px;
			}
		}

		a {
			color: #000;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.bottom {
		padding: 20px 0;
		border-top: 1px solid $border-color-dark;
		overflow: hidden;
		font-size: 12px;
		font-weight: $regular;
		line-height: 16px;

		.copyright {
			float: left;

			@include media("<=tablet") {
				float: none;
				margin-bottom: 7px;
			}
		}

		.developer {
			float: right;

			a {

				&:hover {
					text-decoration: underline;
				}
			}

			@include media("<=tablet") {
				float: none;
			}
		}

	}
}
