/* ANIMATIONS
========================================================================== */
@include keyframes(rotate) {
	0% {
		@include transform(rotate(0deg));
	}
	100% {
		@include transform(rotate(360deg));
	}
}

@include keyframes(fade-in) {
	0% {
		visibility: hidden;
		opacity: 0;
	}
	1% {
		visibility: visible;
		opacity: 0;
	}
	100% {
		visibility: visible;
		opacity: 1;
	}
}

@include keyframes(fade-out) {
	0% {
		visibility: visible;
		opacity: 1;
	}
	99% {
		visibility: visible;
		opacity: 0;
	}
	100% {
		visibility: hidden;
		opacity: 0;
	}
}

@include keyframes(flash-message) {
	0%, 100% {
		@include transform(translate(0, -150%));
	}
	20%, 80% {
		@include transform(translate(0, 0));
	}
}
