// Pagination wrapper
.paging {

}
// Navigation arrows
.paging-navigation-link {

	&.is-inactive {

	}

	&-previous {

	}

	&-next {

	}

}
// Page numbers
.paging-pages {

}
.paging-page {

	&.is-current {

	}

}
.paging-page-link {

	&.is-current {

	}

}
// Missing pages dots placeholder
.paging-dots {

}