* [data-pop-up="1"] {
	@include transform(translate(-50%, -50%));
	
	position: fixed;
	//z-index: z(pop-up);
	z-index: 100;
	display: none;
	top: 50%;
	left: 50%;
	padding: $pop-up-padding;
	background-color: $pop-up-background-color;

	@include media("<=tablet") {
		padding: $pop-up-mobile-padding;
	}
	
	.close-button {
		@include transition(all $default-transition-duration $default-transition-timing-function);
		
		position: absolute;
		top: 10px;
		right: 8px;
		width: 20px;
		height: 20px;
		line-height: 20px;
		padding: 0;
		font-size: 35px;
		font-weight: 300;
		color: #000;
		background-color: transparent;
		border: none;
		
		&:hover,
		&:focus {
			@include transform(scale(1.2, 1.2));

			color: #808285;
		}
	}
	
	.title {
		margin-bottom: 20px;
		font-size: 21px;
		font-weight: 300;
		text-align: center;
		text-transform: uppercase;
	}
	
	.content {
		padding-top: 20px;
		font-size: 12px;
		border-top: 1px solid $light-gray;
		overflow: auto;
	}
}

.pop-up-overlay {
	position: fixed;
	z-index: z(pop-up-overlay);
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.7);
}