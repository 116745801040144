/* FORMS
========================================================================== */
input {
	@extend %form-field;

	&[type="text"],
	&[type="password"] {
		@extend %appearance-none;

		&.input-error {
			border-color: $error-color;
		}
	}

	&[type="radio"],
	&[type="checkbox"] {
		width: 12px !important;
		height: 12px !important;
		cursor: pointer;
	}

	&[type="number"] {
		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
			height: auto;
		}
	}

	&[type="search"] {
		-webkit-box-sizing: content-box;
		-moz-box-sizing: content-box;
		box-sizing: content-box;

		@include appearance(textfield);

		&::-webkit-search-cancel-button,
		&::-webkit-search-decoration {
			@include appearance(none);
		}
	}

	&[disabled],
	&[readonly] {
		@extend %form-field-disabled;
	}

	&:-webkit-autofill {
		-webkit-box-shadow: 0 0 0 1000px $form-field-background-color inset;
	}

	&::-moz-focus-inner {
		padding: 0;
		border: 0;
	}

	&::-ms-clear {
		display: none;
	}
}
select {
	@extend %form-field;
	@extend %appearance-none;

	padding: 0 25px 0 12px;
	padding-right: (22px + nth($form-field-padding, 2));
	background: #fff url($form-field-select-arrow) no-repeat right center;
	line-height: 1.2em !important;
	cursor: pointer;

	&[disabled] {
		@extend %form-field-disabled;
	}

	&::-ms-expand {
		display: none;
	}
}
textarea {
	@extend %form-field;
	@extend %appearance-none;

	&[disabled],
	&[readonly] {
		@extend %form-field-disabled;
	}
}

::-webkit-input-placeholder {
	font-size: 12px;
	font-weight: $regular;
	color: $form-field-placeholder-color;
	line-height: $form-field-placeholder-line-height;
	font-family: "Montserrat", sans-serif;
}
:-moz-placeholder {
	font-size: 12px;
	font-weight: $regular;
	color: $form-field-placeholder-color;
	line-height: $form-field-placeholder-line-height;
	font-family: "Montserrat", sans-serif;
}
::-moz-placeholder {
	font-size: 12px;
	font-weight: $regular;
	color: $form-field-placeholder-color;
	line-height: $form-field-placeholder-line-height;
	font-family: "Montserrat", sans-serif;
}
:-ms-input-placeholder {
	font-size: 12px;
	font-weight: $regular;
	color: $form-field-placeholder-color;
	line-height: $form-field-placeholder-line-height;
	font-family: "Montserrat", sans-serif;
}

.form-row {
	@extend %flex;

	&:not(:last-child) {
		margin-bottom: 20px;
	}

	@include media("<=mobile"){
		display: block;
	}
}
.form-row > .form-column {
	@extend %flex-item;

	&:not(:last-child) {
		margin-right: 20px;

		@include media("<=mobile"){
			margin-right: 0;
			margin-bottom: 20px;
		}
	}

	@include media("<=mobile"){
		display: block;
	}
}
.form-group-text,
.form-group-password {
	position: relative;

	&.has-value {
		.form-label-wrapper {
			top: 8px;
		}

		.form-label {
			font-size: 10px;
		}
	}

	.form-label-wrapper {
		position: absolute;
		top: 20px;
		left: 14px;
	}

	.form-label {
		font-size: 12px;
		color: $light-gray;
		letter-spacing: 0.47px;
		cursor: text;
	}

	.form-element-error {
		position: absolute;
		right: 10px;
		top: -6px;
		padding: 0 5px;
		background-color: #fff;
	}
}
.form-group-checkbox {
	font-size: 0;

	.form-element-wrapper {
		position: relative;
		top: 2px;
		display: inline-block;
		vertical-align: top;
	}

	.form-label-wrapper {
		display: inline-block;
		vertical-align: top;
	}

	.form-label {
		padding-left: 10px;
		font-size: $default-font-size;
		color: $dark-gray;
	}
}
.form-group-radio {

	.form-label-wrapper {
		margin-bottom: 10px;

		.form-label {
			font-size: 14px;
			color: $dark-gray;
		}
	}

	.form-element-wrapper {
		.form-label {

			&:not(:last-child) {
				margin-right: 10px;
			}

			input {
				position: relative;
				top: 1px;
				margin-right: 5px;
			}
		}
	}
}

.form-group-select {
	.form-label-wrapper {
		display: none;
	}
}

.form-element-error {
	color: $error-color;
	font-size: 12px;
}

.form-element-success {
	color: $success-color;
	font-size: 12px;
}
.fieldset-section {

	&:not(:last-child) {
		margin-bottom: 30px;
	}

	.title {
		@extend %h3;

		margin-bottom: 15px;
	}
}

.consents {
	margin-bottom: 10px;

	.form-group-checkbox {
		margin-bottom: 10px;

		.form-label-wrapper {
			width: calc(100% - 15px);
		}

		.form-label {
			display: block;
		}

		.form-element-error {
			padding-left: 22px;
			padding-top: 3px;
			font-weight: $semi-bold;
		}

		&.is-required {

			.form-label-wrapper {
				position: relative;

				&:before {
					content: '*';
					position: absolute;
					top: -1px;
					left: 2px;
					color: $promo-color;
					font-weight: $semi-bold;
					font-size: 13px;
				}
			}
		}
	}
}
