.breadcrumbs {
	font-size: 0;
}

.breadcrumb {
	display: inline-block;
	vertical-align: top;

	&:not(:last-child) {
		margin-right: 10px;
	}

	&:last-child {

		a {
			color: $light-gray;
		}
	}

	a {
		color: #000000;
	}

	i {
		display: inline-block;
		vertical-align: middle;
		margin-left: 10px;
		color: $light-gray;
		font-size: 11px;
	}
}

.breadcrumb-link {
	display: inline-block;
	vertical-align: middle;
	font-size: 11px;
	font-weight: $regular;
	line-height: 15px;
}
