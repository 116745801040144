/* RESET
========================================================================== */
* {
	margin: 0;
	padding: 0;
	border: 0 none;
	outline: 0 none;
	@extend %reset-box-sizing;

	&:before,
	&:after {
		@extend %reset-box-sizing;
	}
}
html {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
html,
body {
	@extend %default-font;
	color: $body-text-color;
}
b,
strong {
	font-weight: $bold;
}
ul,
ol {
	list-style-type: none;
}
hr {
	width: 100%;
	height: 1px;
	margin: 30px 0;
	background: #e7e7e7;
}