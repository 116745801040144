.header-icons {
	width: 100%;
	max-width: 230px;
	text-align: right;
	font-size: 0;

	@include media("<=tablet") {
		position: absolute;
		right: 20px;

		.search-icon {
			display: none;
		}
	}

	a {
		display: inline-block;
		vertical-align: middle;
		font-size: 14px;
		color: $body-text-color;

		@include media("<=tablet") {
			position: relative;
			width: 20px;
			height: 20px;
		}

		&:not(:last-child) {
			margin-right: 40px;

			@include media("<=tablet") {
				margin-right: 23px;
			}
		}

		i {
			@include media("<=tablet") {
				@include align-absolute-center;
				font-size: 16px;
			}
		}
	}

	.header-icon {

		.icon {
			display: block;
			margin: 0 auto;
		}

		.text {
			display: block;
			font-size: 10px;
			color: #000000;
			line-height: 14px;
			font-weight: 400;
			font-family: "Montserrat", sans-serif;
			margin-top: 5px;

			@include media("<=tablet") {
				display: none;
			}
		}

		&.search {

			@include media("<=tablet") {
				display: none;
			}

			.icon {
				background: url('/images/icons/icon-search.svg') no-repeat;
				width: 20px;
				height: 20px;
			}
		}

		&.profile {

			.icon {
				background: url('/images/icons/icon-user.svg') no-repeat;
				width: 18px;
				height: 20px;
			}
		}

		&.cart {
			position: relative;

			&.is-active {

				.count {
					display: block;
				}
			}

			.icon {
				background: url('/images/icons/icon-shopping-bag.svg') no-repeat;
				width: 20px;
				height: 22px;
			}

			.count {
				display: none;
				position: absolute;
				top: -7px;
				right: -6px;
				width: 17px;
				height: 17px;
				background: #000;
				border-radius: 50%;

				& > .number {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					color: #fff;
					font-size: 10px;
					font-weight: 600;
					font-family: "Montserrat", sans-serif;
					line-height: 10px;
				}
			}
		}
	}
}