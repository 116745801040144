.block-sliding-panel-product {
	position: relative;
	font-size: 0;
	padding: 30px 0;

	@include media("<=tablet") {
		padding: 20px 0;
	}

	&:not(:last-child) {
		border-bottom: 1px solid $border-color-light;
	}

	.image {
		display: inline-block;
		vertical-align: top;
		width: 56px;
		margin-right: 20px;
	}

	.info {
		display: inline-block;
		vertical-align: top;
		width: calc(100% - 98px);
	}

	.name {
		display: block;
		margin-bottom: 5px;
		font-family: "Oswald", sans-serif;
		font-size: 14px;
		font-weight: $light;
		color: $body-text-color;
		letter-spacing: $letter-spacing-medium;
	}

	.quantity-price {
		line-height: 24px;
		margin-bottom: 15px;
		font-family: "Oswald", sans-serif;
		font-size: 17px;
		font-weight: $medium;
		letter-spacing: 0.94px;
	}

	.product-prices {
		display: inline-block;
		text-transform: none;

		.price {
			&.is-promo {
				margin-right: 5px;
			}

			&.is-regular {
				margin-right: 0;
			}
		}
	}

	.configurable-attribute {
		font-family: "Oswald", sans-serif;
		font-size: 14px;
		letter-spacing: $letter-spacing-medium;

		.label {
			font-weight: $light;
		}

		.value {
			font-weight: $medium;
		}
	}

	.remove {
		position: absolute;
		top: 26px;
		right: 0;
		background-color: transparent;
		padding: 0;
		font-size: 14px;
		color: $body-text-color;
		letter-spacing: 0;
	}
}
