.product-prices {

  .price {
    color: #000;
    font-size: 13px;
    font-weight: $medium;
    line-height: 16px;
    letter-spacing: 0.5px;

    &.is-regular {
      position: relative;
      margin-right: 5px;

      // Modern strike-through
      &:after {
        content: "";
        position: absolute;
        display: block;
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;
        background: #000000;
      }
    }

    &.is-promo {
      color: $promo-color;
    }
  }
}
