.information-bar {
  position: relative;

  @include media("<=tablet") {
    top: 93px;
    width: 100%;
    display: block;
  }

  .bar {
    position: relative;
    display: block;
    width: 100%;
    background: #000;
    padding: 10px 0;
    max-height: 35px;

    .text {
      display: block;
      width: 100%;
      max-width: 1200px;
      color: #fff;
      margin: 0 auto;
      padding: 0 20px;
      font-size: 13px;
      font-weight: 600;
      text-align: center;
      font-family: "Montserrat", sans-serif;
    }
  }
}