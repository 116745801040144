@import "header-icons";
header {
	position: relative;
	z-index: 50;
	background: #fff;
	border-bottom: 1px solid $border-color-dark;

	&.is-sticky {
		position: fixed;
		top: -40px;
		width: 100%;
		background: #fff;
		border-bottom: 1px solid #E1E1E1;
	}

	@include media("<=tablet") {
		position: fixed;
		top: 0;
		width: 100%;
		background-color: #fff;

		.inner {
			height: 60px !important;
			top: 0;
			left: 0 !important;
		}
	}

	.brands {
		width: 100%;
		background: #EEEEEE;

		& > .inner {
			@extend %fluid-constrained;
			@extend %align-center;
			display: -webkit-box;
			display: -webkit-flex;
			display: -moz-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-webkit-align-items: center;
			-moz-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			justify-content: flex-end;
			position: relative;
			height: 40px;
			padding: 0 20px;

			@include media("<=tablet") {
				justify-content: center;
				height: 32px !important;
			}

			.brand {
				display: inline-block;
				margin-right: 40px;

				@include media("<=tablet") {
					margin-right: 30px;
				}

				&:last-child {
					margin-right: 0;
				}

				&.orsay {
					margin-top: 9px;
				}

				img {
					@include media("<=mobile") {
						max-width: 85px;
					}
				}
			}
		}
	}

	& > .inner {
		@extend %fluid-constrained;
		@extend %align-center;
		display: -webkit-box;
		display: -webkit-flex;
		display: -moz-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-moz-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		height: 80px;
		padding: 0 20px;
	}

	.mobile-icon {
		width: 20px;
		height: 14px;
		margin-right: 15px;
		cursor: pointer;
		transition: all .2s;

		&[data-is-opened-mobile-menu="0"] {
			background: url("/images/icons/icon-bars.svg") no-repeat;
		}

		&[data-is-opened-mobile-menu="1"] {
			background: url("/images/icons/icon-times.svg") no-repeat;
		}
	}

	&[data-type="checkout"] {
		background: transparent;

		.inner {
			position: relative;
			height: 110px;

			.logo {
				display: block;
				margin: 0 auto;

				@include media("<=tablet") {
					margin: 0;
				}
			}
		}
	}

	.logo {
		display: inline-block;
		margin-right: 40px;

		@include media("<=920px") {
			margin-right: 0;
		}

		img {
			@include media("<=tablet") {
				width: 117px;
				height: 25px;
			}
		}
	}

	.navigation {
		display: inline-block;
		width: calc(100% - 200px - 210px);
	}

	.right-section {
		@extend %align-absolute-vertical;

		right: 0;

		@include media("<=tablet") {
			right: 10px;
		}
	}
}
