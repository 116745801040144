/*
* __ˍ__ˍ__ˍ__ˍ__ˍ__ˍ__ˍ__ˍ__ˍ
* ------- News list ---------
* ‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯
*/

.news-list-wrapper {
  width: 100%;
  max-width: 1200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 40px 20px;

  .news-list-title {
    position: relative;
    z-index: 2;
    padding: 0 35px;
    background: #fff;
  }

  .news-categories-menu {
    height: 30px;
  }

  .news-categories-menu-item {
    &:not(:first-child) {
      margin-left: 40px;
    }

    > a {
      font-family: "Roboto Condensed", sans-serif;
      font-size: 14px;
      color: #58595b;
      text-transform: uppercase;
      border-bottom: 1px solid transparent;
    }

    &:hover {
      > a {
        color: #000;
        border-color: #000;
      }
    }
  }

  .news-categories-menu-item.is-active {
    > a {
      color: #000;
      border-color: #000;
    }
  }

  .news-list-content {
    font-size: 0;
  }

  .news-list-column {
    width: 32%;
    margin-right: 2%;

    &:nth-child(3n) {
      margin-right: 0;
    }

    li {
      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }
  }

  .news-list-item-image {
    img {
      max-width: 100%;
    }
  }

  .news-list-item-date {
    font-size: 10px;
    color: #000;
    text-transform: capitalize;
  }

  .news-list-item-title {
    > a {
      font-style: italic;
    }
  }

  .news-list-item-text {
    > a {
      font-size: 13px;
      color: #58595b;

      strong {
        color: #58595b;
      }
    }
  }
}

/*
 * -------------------------
 * ------ Single News ------
 * -------------------------
 */

.single-news-wrapper {

  .single-news-content {
    overflow: hidden;

    img {
      max-width: 100%;
    }
  }

  .single-news-gallery-content {
    font-size: 0;
  }

  .single-news-text {
    margin-bottom: 13px;
    font-size: 16px !important;
    line-height: 22px !important;
  }

  .single-news-gallery-image {
    width: 22%;
    margin-right: 4%;

    &:nth-child(4n) {
      margin-right: 0;
    }
  }
}


@media (max-width: 880px) {

  /*
  * __ˍ__ˍ__ˍ__ˍ__ˍ__ˍ__ˍ__ˍ__ˍ
  * ------- News list ---------
  * ‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯
  */
  .news-list-wrapper {

    .news-list-content {
      padding-left: 10px;
      padding-right: 10px;
    }
    .news-list-column {
      width: 100%;
      margin: 0;
    }
    .news-list-item {
      margin-bottom: 50px;
    }

  }

  /*
   * __ˍ__ˍ__ˍ__ˍ__ˍ__ˍ__ˍ__ˍ__ˍ
   * ------ News single --------
   * ‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯
   */
  .single-news-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
}

body {

  &[data-matched-route-name="newsSingle"],
  &[data-matched-route-name="newsList"] {

    /*
 * __ˍ__ˍ__ˍ__ˍ__ˍ__ˍ__ˍ__ˍ__
 * ------- Containers -------
 * ‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯‾‾
 */
    .layout-full-width {
      display: block;
      width: 100%;
    }

    .layout-fluid {
      width: 100%;
    }

    .layout-fluid-constrained {
      width: 100%;
      max-width: 1220px;
    }

    .layout-fluid-constrained-small {
      max-width: 920px;
    }

    .layout-stretch {
      width: 100%;
      height: 100%;
    }

    .layout-stretch-horizontal {
      width: 100%;
    }

    .layout-stretch-vertical {
      height: 100%;
    }

    .layout-columned:after {
      content: "";
      display: block;
      clear: both;
    }

    .layout-column-left,
    .layout-column-right {
      width: 50%;
    }

    .layout-column-left {
      float: left;
    }

    .layout-column-right {
      float: right;
    }

    /*
     * __ˍ__ˍ__ˍ__ˍ__ˍ__ˍ__ˍ__ˍ__ˍ
     * -------- Alignment --------
     * ‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯
     */
    .layout-align-center {
      display: block;
      margin-left: auto !important;
      margin-right: auto !important;
    }

    .layout-align-content-left {
      text-align: left;
    }

    .layout-align-content-center {
      text-align: center;
    }

    .layout-align-content-center .layout-align-center {
      display: inline-block;
      text-align: left;
    }

    .layout-align-content-right {
      text-align: right;
    }

    .layout-align-middle {
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    .layout-align-bottom {
      position: relative;
      top: 100%;
      -webkit-transform: translateY(-100%);
      -moz-transform: translateY(-100%);
      -ms-transform: translateY(-100%);
      -o-transform: translateY(-100%);
      transform: translateY(-100%);
    }

    .layout-align-left {
      float: left;
    }

    .layout-align-right {
      float: right;
    }

    .layout-align-absolute-left {
      position: absolute;
      left: 0;
    }

    .layout-align-absolute-right {
      position: absolute;
      right: 0;
    }

    .layout-align-absolute-bottom {
      position: absolute;
      bottom: 0;
    }

    .layout-align-absolute-top {
      position: absolute;
      top: 0;
    }

    .layout-align-absolute-center {
      position: absolute;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      transform: translateX(-50%);
    }

    .layout-align-absolute-middle {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    .layout-align-absolute-center.layout-align-absolute-middle {
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }

    /*
     * __ˍ__ˍ__ˍ__ˍ__ˍ__ˍ__ˍ__ˍ__
     * --------- Gutter ---------
     * ‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯‾‾
     */
    .layout-m-0 {
      margin: 0 !important;
    }

    .layout-m-5 {
      margin: 5px;
    }

    .layout-m-10 {
      margin: 10px;
    }

    .layout-m-15 {
      margin: 15px;
    }

    .layout-m-20 {
      margin: 20px;
    }

    .layout-m-25 {
      margin: 25px;
    }

    .layout-m-30 {
      margin: 30px;
    }

    .layout-mt-0 {
      margin-top: 0 !important;
    }

    .layout-mt-5 {
      margin-top: 5px;
    }

    .layout-mt-10 {
      margin-top: 10px;
    }

    .layout-mt-15 {
      margin-top: 15px;
    }

    .layout-mt-20 {
      margin-top: 20px;
    }

    .layout-mt-25 {
      margin-top: 25px;
    }

    .layout-mt-30 {
      margin-top: 30px;
    }

    .layout-mb-0 {
      margin-bottom: 0 !important;
    }

    .layout-mb-5 {
      margin-bottom: 5px;
    }

    .layout-mb-10 {
      margin-bottom: 10px;
    }

    .layout-mb-15 {
      margin-bottom: 15px;
    }

    .layout-mb-20 {
      margin-bottom: 20px;
    }

    .layout-mb-25 {
      margin-bottom: 25px;
    }

    .layout-mb-30 {
      margin-bottom: 30px;
    }

    .layout-mb-35 {
      margin-bottom: 35px;
    }

    .layout-mb-40 {
      margin-bottom: 40px;
    }

    .layout-ml-0 {
      margin-left: 0 !important;
    }

    .layout-ml-5 {
      margin-left: 5px;
    }

    .layout-ml-10 {
      margin-left: 10px;
    }

    .layout-ml-15 {
      margin-left: 15px;
    }

    .layout-ml-20 {
      margin-left: 20px;
    }

    .layout-ml-25 {
      margin-left: 25px;
    }

    .layout-ml-30 {
      margin-left: 30px;
    }

    .layout-mr-0 {
      margin-right: 0 !important;
    }

    .layout-mr-5 {
      margin-right: 5px;
    }

    .layout-mr-10 {
      margin-right: 10px;
    }

    .layout-mr-15 {
      margin-right: 15px;
    }

    .layout-mr-20 {
      margin-right: 20px;
    }

    .layout-mr-25 {
      margin-right: 25px;
    }

    .layout-mr-30 {
      margin-right: 30px;
    }

    .layout-p-0 {
      padding: 0 !important;
    }

    .layout-p-5 {
      padding: 5px;
    }

    .layout-p-10 {
      padding: 10px;
    }

    .layout-p-15 {
      padding: 15px;
    }

    .layout-p-20 {
      padding: 20px;
    }

    .layout-p-25 {
      padding: 25px;
    }

    .layout-p-30 {
      padding: 30px;
    }

    .layout-p-35 {
      padding: 35px;
    }

    .layout-p-40 {
      padding: 40px;
    }

    .layout-pt-0 {
      padding-top: 0 !important;
    }

    .layout-pt-5 {
      padding-top: 5px;
    }

    .layout-pt-10 {
      padding-top: 10px;
    }

    .layout-pt-15 {
      padding-top: 15px;
    }

    .layout-pt-20 {
      padding-top: 20px;
    }

    .layout-pt-25 {
      padding-top: 25px;
    }

    .layout-pt-30 {
      padding-top: 30px;
    }

    .layout-pt-35 {
      padding-top: 35px;
    }

    .layout-pt-40 {
      padding-top: 40px;
    }

    .layout-pb-0 {
      padding-bottom: 0 !important;
    }

    .layout-pb-5 {
      padding-bottom: 5px;
    }

    .layout-pb-10 {
      padding-bottom: 10px;
    }

    .layout-pb-15 {
      padding-bottom: 15px;
    }

    .layout-pb-20 {
      padding-bottom: 20px;
    }

    .layout-pb-25 {
      padding-bottom: 25px;
    }

    .layout-pb-30 {
      padding-bottom: 30px;
    }

    .layout-pb-35 {
      padding-bottom: 35px;
    }

    .layout-pb-40 {
      padding-bottom: 40px;
    }

    .layout-pl-0 {
      padding-left: 0 !important;
    }

    .layout-pl-5 {
      padding-left: 5px;
    }

    .layout-pl-10 {
      padding-left: 10px;
    }

    .layout-pl-15 {
      padding-left: 15px;
    }

    .layout-pl-20 {
      padding-left: 20px;
    }

    .layout-pl-25 {
      padding-left: 25px;
    }

    .layout-pl-30 {
      padding-left: 30px;
    }

    .layout-pl-35 {
      padding-left: 35px;
    }

    .layout-pl-40 {
      padding-left: 40px;
    }

    .layout-pr-0 {
      padding-right: 0 !important;
    }

    .layout-pr-5 {
      padding-right: 5px;
    }

    .layout-pr-10 {
      padding-right: 10px;
    }

    .layout-pr-15 {
      padding-right: 15px;
    }

    .layout-pr-20 {
      padding-right: 20px;
    }

    .layout-pr-25 {
      padding-right: 25px;
    }

    .layout-pr-30 {
      padding-right: 30px;
    }

    .layout-pr-35 {
      padding-right: 35px;
    }

    .layout-pr-40 {
      padding-right: 40px;
    }

    /*
     * __ˍ__ˍ__ˍ__ˍ__ˍ__ˍ__ˍ__ˍ__ˍ
     * --------- Display ---------
     * ‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯
     */
    .layout-block {
      display: block;
    }

    .layout-inline {
      display: inline-block;
    }

    .layout-inline-top {
      vertical-align: top !important;
    }

    .layout-inline-middle {
      vertical-align: middle !important;
    }

    .layout-inline-bottom {
      vertical-align: bottom !important;
    }

    .layout-grid {
      font-size: 0;
      letter-spacing: 0;
    }

    .layout-grid-item {
      display: inline-block;
      vertical-align: top;
    }

    .layout-sidebar {
      width: 280px;
    }

    .layout-section-aside-sidebar {
      width: calc(100% - 280px);
    }

    /*
     * __ˍ__ˍ__ˍ__ˍ__ˍ__ˍ__ˍ__ˍ__ˍ
     * --------- General ---------
     * ‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯
     */
    h1, h2, h3, h4, h5, h6,
    .headline, .headline-1, .headline-2, .headline-3, .headline-4, .headline-5, .headline-6 {
      font-family: "Playfair Display", serif;
      color: #000;
      font-weight: 400;
    }
    h1, .headline-1 {
      font-size: 30px;
    }
    h2, .headline-2 {
      font-size: 24px;
    }
    h3, .headline-3 {
      font-size: 21px;
    }
    h4, .headline-4 {
      font-size: 18px;
    }
    h5, .headline-5 {
      font-size: 16px;
    }
    h6, .headline-6 {
      font-size: 14px;
    }
    /*
     * __ˍ__ˍ__ˍ__ˍ__ˍ__ˍ__ˍ__ˍ__ˍ
     * --------- Styling ---------
     * ‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯‾‾¯
     */
    .headline-bold {
      font-weight: 700;
    }
    .headline-all-caps {
      text-transform: uppercase;
    }
    .headline-letter-spacing {
      letter-spacing: 0.05em;
    }
    .headline-color-dark {
      color: #000;
    }
    .headline-color-light {
      color: #6d6e71;
    }
  }
}