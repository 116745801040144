/* LINKS
========================================================================== */
a,
.link {
	color: $link-text-color;
	text-decoration: $link-text-decoration;
	cursor: pointer;
	
	@if (variable_exists(link-transition)){
		@include transition($link-transition);
	}
	
	&:hover,
	&:focus {
		color: $link-hover-text-color;
		text-decoration: $link-hover-text-decoration;
	}
}