.social-links {
	font-size: 0;
	
	li {
		display: inline-block;
		vertical-align: top;
		width: 21px;
		height: 21px;
		
		&:not(:last-child) {
			margin-right: 20px;
		}
	}
	 
	a {
		display: block;
	}
	
	i {
		font-size: 20px;
		color: #000;
	}
}