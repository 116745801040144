.mobile-icons {
	display: none;
	
	@include media("<=tablet"){
		display: block;
		text-align: center;
		background-color: #000;
		font-size: 0;

	}
	
	> div {
		position: relative;
		display: inline-block;
		vertical-align: top;
		width: 46px;
		height: 46px;
		
		&:last-child {
		}
	}

	.menu {
		
		&:before {
			@include sprite(-1px, -987px);

			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			width: 15px;
			height: 15px;
			margin-top: -8px;
			margin-left: -8px;
		}

		&.is-opened {

			&:before {
				@include sprite(-1px, -1012px);
			}
		}
	}

	.language {
			
		&:before {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			width: 18px;
			height: 13px;
			margin-top: -7px;
			margin-left: -9px;
			border: 1px solid #fff;
			background: url("/images/flags.png") no-repeat -1px -43px;
		}

		&.is-opened {
		}
	}
	
	.profile {
		
		> a {
			display: block;
			position: relative;
			width: 100%;
			height: 100%;
			
			&:before {
				@include sprite(-1px, -73px);
				
				content: "";
				position: absolute;
				top: 50%;
				left: 50%;
				width: 14px;
				height: 16px;
				margin-top: -8px;
				margin-left: -7px;
			}
		}
	}
	
	.search {
		
		&:before {
			@include sprite(-1px, -115px);

			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			width: 16px;
			height: 16px;
			margin-top: -7px;
			margin-left: -8px;
		}
		
		&.is-opened {
			
			&:before {
				@include sprite(-1px, -1063px);
			}
		}
	}
	
	.favourites {
		
		> a {
			display: block;
			position: relative;
			width: 100%;
			height: 100%;

			&:before {
				@include sprite(-1px, -45px);

				content: "";
				position: absolute;
				top: 50%;
				left: 50%;
				width: 17px;
				height: 16px;
				margin-top: -8px;
				margin-left: -8px;
			}
		}
		
		.count {
			position: absolute;
			top: 7px;
			right: 7px;
			font-size: 12px;
			font-weight: $bold;
			color: #fff;
		}
	}

	.cart {
		
		> a {
			display: block;
			position: relative;
			width: 100%;
			height: 100%;

			&:before {
				@include sprite(-1px, -20px);

				content: "";
				position: absolute;
				top: 50%;
				left: 50%;
				width: 13px;
				height: 15px;
				margin-top: -7px;
				margin-left: -6px;
			}
		}
		
		.count {
			position: absolute;
			top: 7px;
			right: 7px;
			font-size: 12px;
			font-weight: $bold;
			color: #fff;
		}
	}
}